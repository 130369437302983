<template>
  <section class="d-flex fh fw login">
    <v-card class="fh fw" :elevation="0" tile rounded="0">
      <img :src="require('images/login.png')" class="fh pointer" alt="vgo" @click="imgClicked" />
    </v-card>
    <v-card class="fh fw d-flex pa-10 justify-center" :elevation="0" tile rounded="0">
      <router-view class="content" />
    </v-card>
  </section>
</template>

<script>
export default {
  methods: {
    imgClicked() {
      window.open("https://en.v-go.io", "_blank");
    },
  },
};
</script>

<style></style>
